'use client';
import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '@/app/_components/form/FormInput';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { FilesMeta } from '@/app/_types/file';
import { RiskAssessment } from '@/app/_types/risk-assessment-page';
import { saveFormData } from '@/app/actions';

import FormHeader from './FormHeader';
import HumanRiskElement from './HumanRiskElement';
import RiskAnalysisInitiative from './RiskAnalysisInitiative';
import RiskAssessmentQuestionnaire from './RiskAssessmentQuestionnaire';
import Styles from './RiskAssessmentQuestionnaire.module.scss';
import TechnologyRiskAssessment from './TechnologyRiskAssessment';
import ThankYou from './ThankYou';

type Props = RiskAssessment & {
  filesMeta?: FilesMeta;
  breadcrumbs?: Breadcrumb[] | null;
};

const RiskAnalysisPage = (props: Props) => {
  const { filesMeta, breadcrumbs } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const { form_inputs, submission_key } = props.risk_analysis_form;
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const formHeaderData = {
    description: props.risk_analysis_form.description,
    pre_text: props.risk_analysis_form.pre_text,
    title: props.risk_analysis_form.title,
  };

  const thankYouData = {
    submitted_title: props.risk_analysis_form.submitted_title,
    submitted_paragraph: props.risk_analysis_form.submitted_paragraph,
    submitted_image: props.risk_analysis_form.submitted_image,
  };

  const riskElementData = {
    title: props.submitted_copy.title,
    paragraph: props.submitted_copy.paragraph,
  };

  const pathname = usePathname();
  const saveData = saveFormData.bind(
    null,
    pathname,
    submission_key,
    props.risk_analysis_form.id,
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  const handleSubmit = async (data: any): Promise<boolean> => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return false;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      return false;
    } else {
      form.resetFields();
      setTurnstileToken(null);
      return true;
    }
  };

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      <FormHeader {...formHeaderData} />
      {contextHolder}
      {!formSubmitted && (
        <Form
          form={form}
          preserve={true}
          onFinish={(data) => {
            handleSubmit(data).then((success) => setFormSubmitted(success));
          }}
        >
          <RiskAnalysisInitiative
            form={form}
            formInputs={form_inputs.slice(0, form_inputs.length - 1)}
          />
          <TechnologyRiskAssessment />
          <RiskAssessmentQuestionnaire />

          <div className="container">
            <div className={Styles.checkBoxWrapper}>
              {/* The last form input should be the T & C checkbox */}
              <FormInput
                form={form}
                input={form_inputs[form_inputs.length - 1]}
              />
            </div>
            <CloudflareTurnstile
              onSuccess={getTurnstileToken}
              onError={() => setIsTurnstileLoading(false)}
              onLoad={() => setIsTurnstileLoading(true)}
            />
            <div className={Styles.submitButtonWrapper}>
              <ButtonCustom
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched() || !isFormValid || isTurnstileLoading
                }
              >
                {props.risk_analysis_form.submit_text}
              </ButtonCustom>
            </div>
          </div>
        </Form>
      )}
      {formSubmitted && (
        <>
          <ThankYou {...thankYouData} filesMeta={filesMeta} />
          <HumanRiskElement {...riskElementData} />
        </>
      )}
    </>
  );
};

export default RiskAnalysisPage;
