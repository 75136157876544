'use client';
import React from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import ImageMarquee from '@/app/_components/image-marquee/ImageMarquee';
import PageContent from '@/app/_components/page-content/PageContent';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { FilesMeta } from '@/app/_types/file';
import { OurCorporateResponsibilityType } from '@/app/_types/our-corporate-responsibility';

import CtaBanner from './CtaBanner';

type Props = OurCorporateResponsibilityType & {
  breadcrumbs?: Breadcrumb[] | null;
  filesMeta?: FilesMeta;
};

const OurCorporateResponsibilityPage = (props: Props) => {
  const { header, content, image_scroller, breadcrumbs, filesMeta } = props;

  const transformedImageScroller = image_scroller?.map((item, index) => ({
    id: index,
    image: item.directus_files_id,
  }));

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {content &&
        content?.length > 0 &&
        content.map((val, index) => (
          <PageContent {...val.copy_component_id} key={index} />
        ))}
      {image_scroller && (
        <ImageMarquee
          slider={transformedImageScroller ?? []}
          filesMeta={filesMeta ?? {}}
        />
      )}
    </>
  );
};

export default OurCorporateResponsibilityPage;
