'use client';

import { Button } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import React, { ReactNode } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import BackIcon from '@/app/_icons/back-arrow.svg';
import closeIcon from '@/app/_icons/close.svg';
import { HeaderComponent } from '@/app/_types/components/header-component';

import styles from './StepperWrapper.module.scss';

type Props = {
  children: ReactNode;
  isShowBack?: boolean;
  selectedProduct?: string;
  label?: string;
  onNext?: () => void;
  onBack?: () => void;
  stepTitle?: string;
  stepTitleTwo?: string;
  stepDescription?: string;
  isDisableNext?: boolean;
  header?: HeaderComponent;
  mainCategory?: string;
  isCardComponent?: boolean;
  handleClose?: () => void;
};

const StepWrapper = ({
  children,
  isShowBack = false,
  onNext,
  onBack,
  label,
  stepTitle,
  stepTitleTwo,
  stepDescription,
  isDisableNext,
  header,
  mainCategory,
  isCardComponent,
  handleClose,
}: Props) => {
  return (
    <div className="findSolutionPageSection">
      <div className={cn('headingElement', styles.mainStepperWrapper)}>
        <div className={styles.headingWrapper}>
          {mainCategory && <h3 className="subTitle">For {mainCategory}</h3>}
          <h1 className={'titleXl normal'}>
            {header?.static_title}{' '}
            {header &&
              header.dynamic_titles
                ?.map((title) => title.option.toLowerCase())
                .join(' ')}
          </h1>
        </div>
        <div className={styles.boxWrapper}>
          <div className={styles.topButtonWrapper}>
            {isShowBack && (
              <Button type="text" onClick={onBack} className={styles.backBtn}>
                <Image src={BackIcon} alt="Back" height={14} width={14} />
                <span className={styles.backBtnTest}>Back</span>
              </Button>
            )}
            <Image
              src={closeIcon}
              alt="Back"
              height={14}
              width={14}
              onClick={handleClose}
              className={styles.closeIcon}
            />
          </div>
          <div className={styles.stepHeadingWrapper}>
            <h4 className={cn('titleLg', styles.stepHeading)}>
              {stepTitle}{' '}
              {stepTitleTwo && <span className="strong">{stepTitleTwo}</span>}
            </h4>
            <p className="normal smallSubTitle">{stepDescription}</p>
          </div>
          {children}
          {!isCardComponent && (
            <div
              className={cn(styles.nextButtonWrapper, {
                [styles.singleButton]: !label,
              })}
            >
              <ButtonCustom
                onClick={onNext}
                btnText="Next"
                disabled={isDisableNext}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepWrapper;
