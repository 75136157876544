'use client';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Collapse, Table } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { useWindowSize } from '@/app/_hooks/window-size.hook';
import { Button } from '@/app/_types/components/button';
import { LocationCard } from '@/app/_types/components/location-card';
import { parseCoordinate } from '@/app/_utils/text';

import NoResultsFound from '../NoResultsFound';

import Styles from './ResultListing.module.scss';

const { Panel } = Collapse;

const mapContainerStyle = {
  width: '100%',
  height: '486px',
};

type ResultListingProps = {
  vendorBranch?: any[];
  backToStartUrl?: Button;
  preText?: string;
  title?: string;
  paragraph?: string;
  headOffice?: LocationCard | null;
  noVendorIcon: string;
  noVendorTitle: string;
  noVendorParagraph: string;
  backToStartButton: Button;
  vendorDetailNavigation?: string | null | undefined;
};

const defaultCenter = { lat: -26.1438, lng: 28.0248 };

const ResultListing = (props: ResultListingProps) => {
  const { width } = useWindowSize();
  const {
    backToStartUrl,
    vendorBranch,
    preText,
    title,
    paragraph,
    headOffice,
    noVendorIcon,
    noVendorTitle,
    noVendorParagraph,
    backToStartButton,
    vendorDetailNavigation,
  } = props;

  const router = useRouter();

  const vendorBranchData = vendorBranch || [];

  const headOfficeCoordinates =
    headOffice && headOffice.coordinates
      ? parseCoordinate(headOffice.coordinates)
      : null;

  const { isLoaded: isGoogleMapsLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY),
  });

  const filteredVendorBranchData = vendorBranchData.map((record) => {
    const {
      trading_name,
      contact_phone_number,
      contact_telephone_number,
      email,
      address,
    } = record;

    return {
      trading_name,
      contact_phone_number,
      contact_telephone_number,
      email,
      address,
    };
  });

  const dynamicColumns = filteredVendorBranchData[0]
    ? Object.keys(filteredVendorBranchData[0]).map((key) => ({
        title: key
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        dataIndex: key,
        key: key,
      }))
    : [];

  return (
    <div className={Styles.resultListingWrapper}>
      <div className={Styles.sectionHeadingContainer}>
        <div className="headingElement">
          {preText && <h3 className="subTitle">{preText}</h3>}
          {title && <h2 className="titleXl">{title}</h2>}
        </div>
        {paragraph && (
          <div
            className={Styles.sectionDescription}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        )}
      </div>
      {vendorBranchData.length > 0 ? (
        <>
          {width && width > 991 ? (
            <Table
              columns={dynamicColumns}
              dataSource={vendorBranchData}
              className={Styles.resultTable}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record) => ({
                onClick: () => {
                  router.push(`${vendorDetailNavigation}/${record.id}`);
                },
              })}
            />
          ) : (
            <div className={Styles.resultMobileTable}>
              <div className={Styles.resultCollapseHeader}>
                {dynamicColumns[0] && (
                  <div className={Styles.collapseHeading}>
                    {dynamicColumns[0]?.title}
                  </div>
                )}
                {dynamicColumns[1] && (
                  <div className={Styles.collapseHeading}>
                    {dynamicColumns[1]?.title}
                  </div>
                )}
              </div>
              {vendorBranchData.map((record, index) => (
                <Collapse key={index} accordion expandIconPosition="end">
                  <Panel
                    header={
                      <Link href={`${vendorDetailNavigation}${record.id}`}>
                        <div className={Styles.customPanelHeader}>
                          <div className={Styles.customPanelHeading}>
                            {record?.trading_name}
                          </div>
                          <div className={Styles.customPanelHeading}>
                            {record?.contact_phone_number}
                          </div>
                        </div>
                      </Link>
                    }
                    key={index}
                  >
                    <div className={Styles.panelBox}>
                      <div className={Styles.panelBoxRow}>
                        <div className={Styles.panelBoxRowTitle}>
                          {dynamicColumns[2]?.title}:
                        </div>
                        <Link href={`${vendorDetailNavigation}${record.id}`}>
                          <div className={Styles.panelBoxRowContent}>
                            {record?.contact_telephone_number}
                          </div>
                        </Link>
                      </div>
                      <div className={Styles.panelBoxRow}>
                        <div className={Styles.panelBoxRowTitle}>
                          {dynamicColumns[3]?.title}:
                        </div>
                        <Link href={`${vendorDetailNavigation}${record.id}`}>
                          <div className={Styles.panelBoxRowContent}>
                            {record?.email}
                          </div>
                        </Link>
                      </div>
                      <div className={Styles.panelBoxRow}>
                        <div className={Styles.panelBoxRowTitle}>
                          {dynamicColumns[4]?.title}:
                        </div>
                        <Link href={`${vendorDetailNavigation}${record.id}`}>
                          <div className={Styles.panelBoxRowContent}>
                            {record?.address}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              ))}
            </div>
          )}
        </>
      ) : (
        <NoResultsFound
          noVendorIcon={noVendorIcon}
          noVendorTitle={noVendorTitle}
          noVendorParagraph={noVendorParagraph}
          backToStartButton={backToStartButton}
        />
      )}

      <div className="headingElement">
        <h3 className="subTitle">Find Us</h3>
        <h2 className="titleXl">Here are our offices</h2>
      </div>

      {!isGoogleMapsLoaded ? (
        <div>Loading Maps...</div>
      ) : (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={headOfficeCoordinates || defaultCenter}
          zoom={15}
        >
          {headOfficeCoordinates && <Marker position={headOfficeCoordinates} />}
        </GoogleMap>
      )}

      {backToStartUrl && (
        <div className="text-center">
          <ButtonCustom href={backToStartUrl.url || undefined}>
            {backToStartUrl.text}
          </ButtonCustom>
        </div>
      )}
    </div>
  );
};

export default ResultListing;
