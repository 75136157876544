import React from 'react';

import Styles from './FormHeader.module.scss';

type Props = {
  pre_text?: string | null;
  title?: string | null;
  description?: string | null;
};

const FormHeader = (props: Props) => {
  const { pre_text, title, description } = props;

  return (
    <div className="container">
      <div className={Styles.formHeaderWrapper}>
        <div className="headingElement">
          {pre_text && <h3 className="subTitle">{pre_text}</h3>}
          {title && <h1 className="titleXl">{title}</h1>}
        </div>

        {description && (
          <div
            className={Styles.formHeaderDescription}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default FormHeader;
