'use client';

import { Divider } from 'antd';
import React, { useState } from 'react';

import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import PageContent from '@/app/_components/page-content/PageContent';
import { CoverageComponent } from '@/app/_types/components/coverage';
import {
  ManagerRepeater,
  Partner,
  PartnerM2M,
} from '@/app/_types/components/partner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './PartnershipTabs.module.scss';

type PartnershipTabsTypes = {
  partners: PartnerM2M[];
  title?: string | null;
  paragraph?: string | null;
  preText?: string | null;
  filesMeta: FilesMeta;
  handlePartnerCoverage?: (coverage: CoverageComponent | null) => void;
  handleManagers?: (managers: ManagerRepeater[] | null) => void;
  handleManagerHeader?: (title: string, preText: string) => void;
};

const PartnershipTabs = (props: PartnershipTabsTypes) => {
  const {
    title,
    preText,
    paragraph,
    partners,
    filesMeta,
    handlePartnerCoverage,
    handleManagers,
    handleManagerHeader,
  } = props;

  const [activeTab, setActiveTab] = useState<number | null>(0);

  const handleChangeTab = (index: number, partner: Partner) => {
    setActiveTab(index);
    handlePartnerCoverage?.(partner.coverage || null);
    handleManagers?.(partner.managers || null);
    handleManagerHeader?.(
      partner.manager_title || 'Contact details',
      partner.manager_pre_text || 'Get in touch',
    );
  };

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.partnershipWrapper}>
          <div className={Styles.partnershipContentContainer}>
            <div className={Styles.partnershipContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={Styles.partnershipContentDetails}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}
          </div>
          <div className={Styles.partnershipTabsContainer}>
            <div className={Styles.partnershipTabsCardsList}>
              {partners &&
                partners?.map(({ partners_component_id }, index) => {
                  return (
                    <div
                      key={index}
                      className={`${Styles.partnershipTabsCard} ${activeTab === index ? Styles.active : ''}`}
                      onClick={() =>
                        handleChangeTab(index, partners_component_id)
                      }
                    >
                      <div className={Styles.partnershipTabsCardImage}>
                        <NoDownloadImage
                          src={
                            getDirectusAssetURI(
                              partners_component_id?.logo ?? '',
                            ) || ''
                          }
                          alt={getAltProperty(
                            filesMeta,
                            partners_component_id?.logo ?? '',
                            'product logo',
                          )}
                          width={468}
                          height={188}
                          quality={100}
                        />
                      </div>

                      <div className={Styles.partnershipTabsCardBody}>
                        <div className="headingElement">
                          <h3 className="titleSm">
                            {partners_component_id?.title}
                          </h3>
                        </div>
                        <div
                          className={Styles.description}
                          dangerouslySetInnerHTML={{
                            __html: partners_component_id?.paragraph,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Divider className={Styles.quickLinkDivider} />
            {activeTab !== null &&
              partners?.[activeTab]?.partners_component_id &&
              partners?.[activeTab]?.partners_component_id?.details &&
              partners[activeTab].partners_component_id.details.map(
                ({ copy_component_id }, idx) => (
                  <div className={Styles.partnershipTabContent} key={idx}>
                    <PageContent
                      {...copy_component_id}
                      smallContent={true}
                      btnVariant="btnPrimaryOutline"
                    />
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipTabs;
