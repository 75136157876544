'use client';
import cn from 'classnames';
import React from 'react';

import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import { assetsUri } from '@/app/_services/api.service';

import styles from './ProductCard.module.scss';

const ProductCard = ({ option, handleSetSelected, isSelected }: any) => {
  const { option_title, option_sub_title, image } = option;

  return (
    <div
      className={cn(styles.productCard, { [styles.selected]: isSelected })}
      onClick={() => handleSetSelected(option)}
    >
      <NoDownloadImage
        src={`${assetsUri}/${image}`}
        alt="card-image"
        width={100}
        height={100}
        className={styles.image}
      />
      <div className={styles.productCardBody}>
        <div className="headingElement">
          <h2 className={cn('titleMd', 'light', styles.heading)}>
            {option_title}
          </h2>
        </div>
        <div
          className={cn('normal', styles.description, {
            [styles.descriptionAlignment]: true,
          })}
        >
          {option_sub_title}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
