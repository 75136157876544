'use client';

import { useEffect, useState } from 'react';

import { HeaderComponent } from '@/app/_types/components/header-component';
import { Product } from '@/app/_types/components/product';
import { FindMySolutionNode } from '@/app/_types/find-my-solution';

import ProductCard from '../product-card/ProductCard';
import StepWrapper from '../step-wrapper/StepWrapper';
import styles from '../step1/Step-1.module.scss';
interface CardStepProps {
  data: any;
  onNext: () => void;
  onBack: () => void;
  setCurrentStepData: (e: FindMySolutionNode) => void;
  handleFinalStep: (products: Product[]) => void;
  header: HeaderComponent;
  currentStepIndex: number;
  mainCategory: string;
  setMainCategory: (e: string) => void;
  handleClose: () => void;
}

const CardStep = ({
  data,
  onNext,
  onBack,
  setCurrentStepData,
  handleFinalStep,
  header,
  currentStepIndex,
  mainCategory,
  setMainCategory,
  handleClose,
}: CardStepProps) => {
  const [questionOptions, setQuestionOptions] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<FindMySolutionNode | null>(
    null,
  );

  const handleSetSelected = (option: any) => {
    if (currentStepIndex === 1) {
      setMainCategory(option.option_title);
    }
    setSelectedItem(option);
  };

  const handleNext = () => {
    if (selectedItem) {
      const children = selectedItem?.children ?? [];

      if (children.length > 0) {
        const parentData = children[0];

        if (parentData.is_question) {
          setQuestionOptions(parentData.children);
          setCurrentStepData(parentData);
          onNext();
        }
      } else {
        handleFinalStep([selectedItem]);
      }
    }
  };

  useEffect(() => {
    if (data.children) {
      setQuestionOptions(data.children);
      setSelectedItem(null);
    }
  }, [data]);

  useEffect(() => {
    if (selectedItem) {
      handleNext();
    }
  }, [selectedItem]);

  return (
    <StepWrapper
      isShowBack={true}
      onNext={handleNext}
      onBack={onBack}
      label={'label'}
      stepTitle={data.question}
      stepTitleTwo={data.question_title_2}
      stepDescription={data.question_help_text}
      isDisableNext={false}
      header={header}
      mainCategory={mainCategory}
      isCardComponent={true}
      handleClose={handleClose}
    >
      <div
        className={
          questionOptions.length > 2
            ? styles.productCardWrapper
            : styles.productCardWrapperTwo
        }
      >
        {questionOptions.map((option: any) => {
          return (
            <div key={option.id}>
              <ProductCard
                option={option}
                isSelected={
                  selectedItem ? option.id === selectedItem?.id : false
                }
                handleSetSelected={handleSetSelected}
              />
            </div>
          );
        })}
      </div>
    </StepWrapper>
  );
};

export default CardStep;
