'use client';

import Link from 'next/link';
import React from 'react';

import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import { ProductM2M } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './IconCardSection.module.scss';

type IconCardProps = {
  heading: string;
  subHeading: string;
  products: ProductM2M[];
  filesMeta: FilesMeta;
};

const IconCardSection = (props: IconCardProps) => {
  const { products, heading, subHeading, filesMeta = {} } = props;

  return (
    <div>
      <div className="pageSection">
        <div className="container">
          <div className={Styles.iconCardWrapper}>
            <div className={Styles.iconCardContentContainer}>
              <div className={Styles.iconCardContentHeadingSection}>
                {heading && (
                  <div className="headingElement">
                    {subHeading && <h3 className="subTitle">{subHeading}</h3>}
                    <h2 className="titleXl">{heading}</h2>
                  </div>
                )}
              </div>
            </div>
            {products && (
              <div className={Styles.iconCardLists}>
                <div className={Styles.desktopGrid}>
                  {products.map((productM2M) => {
                    const product = productM2M.products_id;
                    const productDetail =
                      typeof product === 'object' ? product : null;

                    return (
                      <Link
                        key={productM2M.id}
                        href={productDetail?.learn_more_cta_url ?? ''}
                      >
                        <div className={Styles.iconCard}>
                          <NoDownloadImage
                            src={
                              getDirectusAssetURI(productDetail?.icon ?? '') ||
                              ''
                            }
                            alt={getAltProperty(
                              filesMeta,
                              productDetail?.icon ?? '',
                              'Banner Image',
                            )}
                            width={60}
                            height={60}
                          />
                          <div className={Styles.iconCardBody}>
                            <h4 className={Styles.title}>
                              {productDetail?.title}
                            </h4>
                            {productDetail?.subtitle && (
                              <div
                                className={Styles.description}
                                dangerouslySetInnerHTML={{
                                  __html: productDetail?.subtitle,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconCardSection;
