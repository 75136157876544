'use client';
import { Form, Radio } from 'antd';
import React from 'react';

import FloatTextarea from '@/app/_components/float-textarea/FloatTextarea';
import RadioCustom from '@/app/_components/radio/RadioCustom';

import Styles from './TechnologyRiskAssessment.module.scss';

const TechnologyRiskAssessment = () => {
  return (
    <div className="container">
      <div className={Styles.technologyRiskAssessmentWrapper}>
        <div>
          <div className="headingElement full-width mb-4">
            <h3 className="titleMd">Technology Risk Assessment</h3>
          </div>
          <p>
            The Technology Risk Assessment comprises of a high-level
            vulnerability scan, as well as a short questionnaire.
          </p>
        </div>
        <div className="headingElement ">
          <h4 className="titleSm text-underline">A. Vulnerability Scan</h4>
        </div>
        <p>
          A vulnerability scan is effectively a site inspection that establishes
          if there are any access point of unwanted entry by threats. Phishield
          employs the services of a third party, Cybersafe Consultants, to
          perform the scan to Phishield’s partners on behalf of their customers.
        </p>
        <div className={Styles.vulnerabilityScanForm}>
          <Form.Item
            name="ipAddresses"
            key="ipAddresses"
            className="customInput"
          >
            <FloatTextarea
              label="Please provide a list of Internet Facing IP Addresses to be scanned"
              name="ipAddresses"
              key="ipAddresses"
              value={''}
            />
          </Form.Item>
          <div className={Styles.ipDiscoverySection}>
            <p className="strong mb-1 base-font">IP address discovery</p>
            <p className="text-neutral07">
              If you do not know your IP addresses, they can be auto discovered
              with your consent.
              <br />
              (Please note that not all IP addresses are discoverable. Neither
              Phishield nor Cybersafe guarantee the accuracy of IP searches)
            </p>
            <Form.Item
              name="ipAddressesAutodiscover"
              key="ipAddressesAutodiscover"
              className="customInput"
              required={true}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Radio.Group
                className={Styles.radioGroup}
                name="ipAddresses"
                key="ipAddresses"
              >
                <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
                <RadioCustom name={'no'} label={'No'} value={'no'} />
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <div>
          <div className="headingElement mb-3">
            <h4 className="titleSm ">Authorisation</h4>
          </div>
          <p>
            By submitting your information, you, the Customer, hereby authorise
            CyberSafe to use this information to perform the agreed ethical
            hacking on behalf of Phishield UMA (Pty) Ltd, which is necessary to
            evaluate the security of the Customer’s computer network resources(
            Internet Facing addresses, ranges, or any other additional
            resources). By submitting this form, the Customer certifies that all
            the information provided including the IP addresses of devices
            belonging to the customer’s organisation is correct. Your
            information, including personal information collected, will only be
            used to perform the above agreed ethical hacking and, vulnerability
            scan and provide the results to yourself and Phishield for further
            assistance
          </p>
        </div>
      </div>
    </div>
  );
};

export default TechnologyRiskAssessment;
