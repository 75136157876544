export const minWideDesktopScreen = 1300;
export const minDesktop = 1025;
export const maxTablet = 1024;
export const minTablet = 700;
export const maxSmallMobile = 450;
export const desktop = 992;

export const Status = {
  Published: 'published',
  Draft: 'draft',
  Archived: 'archived',
  Inactive: 'inactive',
};

export const DynamicPage = {
  HomePageVariation1: 'home_page_variation_1',
  HomePageVariation2: 'home_page_variation_2',
  HomePageVariation3: 'home_page_variation_3',
  HomePageVariation4: 'home_page_variation_4',
  AboutUsVariation1: 'about_us_variation_1',
  AboutUsVariation2: 'about_us_variation_2',
  AboutUsVariation3: 'about_us_variation_3',
  AboutUsVariation4: 'about_us_variation_4',
  ContactPage: 'contact_page',
  ProductCategoryVariation1: 'product_catagory_variation_1',
  ProductCategoryVariation2: 'product_category_variation_2',
  ProductCategoryVariation3: 'product_category_variation_3',
  ProductOverview: 'product_overview_page',
  RiskAssessmentPage: 'risk_assessment_page',
  ProductLandingVariation1: 'insurance_product_landing_v1',
  ProductLandingVariation2: 'insurance_product_landing_v2',
  ProductLandingVariation3: 'insurance_product_landing_v3',
  ProductLandingVariation4: 'insurance_product_landing_v4',
  ProductLandingShariahVariation: 'insurance_product_landing_shariah_v1',
  GetInTouch: 'lead_generation',
  ResourcesVariation1: 'resources_page',
  ResourcesVariation2: 'resources_page_v2',
  Careers: 'careers_page',
  AgentUmaPage: 'agent_uma_page',
  LegalPage: 'legal_page',
  OurCorporateResponsibility: 'corporate_responsibility',
  FormsAndDocumentPage: 'forms_and_documents_page',
  GuidelinePage: 'guidelines_page',
  SearchResultsPage: 'search_results_page',
  FindMySolutionPage: 'find_my_solution_page',
  SearchProviderPage: 'search_provider_page',
  MediaPage: 'media_page',
  BlogDetailPage: 'blog_detail_page',
  EducationalBlogsPage: 'educational_blogs_page',
  MediaDetailPage: 'media_detail_page',
  ArchivePage: 'archives_page',
  VendorDetailPage: 'vendor_detail_page',
  // TODO: add all the other pages with variations
};

export type DynamicPageKey = keyof typeof DynamicPage;
export type DynamicPageValue = (typeof DynamicPage)[DynamicPageKey];

export enum SearchCategory {
  Insurance = 'Insurance',
  Resources = 'Resources',
  Media = 'Media',
  AgentsAndUMAs = 'Agents & UMAs',
  Pages = 'Pages',
}

export const allSearchCategories = [
  SearchCategory.Insurance,
  SearchCategory.Resources,
  SearchCategory.Media,
  SearchCategory.AgentsAndUMAs,
  SearchCategory.Pages,
];

export enum SearchFlow {
  Insurance = 'search-insurance',
  Media = 'search-media',
  Resources = 'search-resources',
  Pages = 'search-pages',
  AgentsAndUMAs = 'search-agents',
}

export const allSearchCategoriesByRegion: Record<string, SearchCategory[]> = {
  '1': [
    SearchCategory.Insurance,
    SearchCategory.Resources,
    SearchCategory.Media,
    SearchCategory.AgentsAndUMAs,
    SearchCategory.Pages,
  ],
  '3': [
    SearchCategory.Insurance,
    SearchCategory.Resources,
    SearchCategory.Media,
    SearchCategory.Pages,
  ],
};

export const categoryFlowMapping = {
  [SearchCategory.Insurance]: SearchFlow.Insurance,
  [SearchCategory.Resources]: SearchFlow.Resources,
  [SearchCategory.Media]: SearchFlow.Media,
  [SearchCategory.AgentsAndUMAs]: SearchFlow.AgentsAndUMAs,
  [SearchCategory.Pages]: SearchFlow.Pages,
};
