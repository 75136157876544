'use client';
import React, { useRef } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { CareersImages } from '@/app/_types/components/career-images';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './ImageMarquee.module.scss';

type ImageMarqueeProps = {
  slider: CareersImages[];
  filesMeta: FilesMeta;
};

const ImageMarquee = (props: ImageMarqueeProps) => {
  const { slider, filesMeta } = props;
  const swiperRef = useRef<any>(null);

  const swiperConfig: Parameters<typeof Swiper>[0] = {
    spaceBetween: 40,
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        spaceBetween: 0,
        slidesPerView: 'auto',
      },
    },
  };

  return (
    <div className="pageSection">
      <div className={Styles.imageMarqueeWrapper}>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay]}
          className="mySwiper"
          {...swiperConfig}
        >
          {slider.map((item, index) => (
            <SwiperSlide key={index} className={Styles.imageMarqueeContaier}>
              <div className={Styles.imageMarqueeLink}>
                <NoDownloadImage
                  src={getDirectusAssetURI(item?.image ?? '') || ''}
                  alt={getAltProperty(filesMeta, item?.image ?? '', 'Image')}
                  width={574}
                  height={666}
                  className={Styles.imageMarquee}
                  quality={90}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ImageMarquee;
