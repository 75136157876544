import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './ProductCard.module.scss';

type ProductCardProps = {
  icon?: string | null;
  title?: string | null;
  url?: string | null;
  paragraph?: string | null;
  filesMeta?: FilesMeta;
};

const ProductCard = (props: ProductCardProps) => {
  const { icon, title, paragraph, url, filesMeta = {} } = props;

  return (
    <Link href={url ?? ''} className={Styles.productCard}>
      <NoDownloadImage
        src={getDirectusAssetURI(icon ?? '') || ''}
        alt={getAltProperty(filesMeta, icon ?? '', 'Icon')}
        width={50}
        height={50}
        priority
      />
      <div className={Styles.productCardBody}>
        <div className="headingElement">
          <h3 className="titleMd">{title}</h3>
        </div>
        {paragraph && (
          <div
            className={cn(Styles.description, 'editorDescription')}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        )}
      </div>
    </Link>
  );
};

export default ProductCard;
