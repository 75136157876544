'use client';

import cn from 'classnames';
import React from 'react';

import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import { OfferingCardM2M } from '@/app/_types/components/offering-card';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './OfferingSection.module.scss';

type OfferingProps = {
  offerings: OfferingCardM2M[] | null;
  preText?: string | null;
  title?: string | null;
  paragraph?: string | null;
  filesMeta: FilesMeta;
};

const OfferingSection = (props: OfferingProps) => {
  const { preText, title, paragraph, offerings, filesMeta } = props;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.offeringWrapper}>
          <div className={Styles.offeringContentContainer}>
            <div className={Styles.offeringContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={Styles.offeringContentDetails}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}
          </div>

          {offerings && (
            <div className={Styles.offeringList}>
              {offerings.map(({ offering_cards_id }, index) => (
                <div key={index} className={Styles.offeringCard}>
                  <NoDownloadImage
                    src={
                      getDirectusAssetURI(offering_cards_id?.icon ?? '') || ''
                    }
                    alt={getAltProperty(
                      filesMeta,
                      offering_cards_id?.icon ?? '',
                      'Icon',
                    )}
                    width={80}
                    height={80}
                  />
                  <div className={Styles.offeringCardBody}>
                    <div className="headingElement">
                      <h3 className="titleSm">{offering_cards_id?.title}</h3>
                    </div>
                    {offering_cards_id?.paragraph && (
                      <div
                        className={cn(Styles.description, 'editorDescription')}
                        dangerouslySetInnerHTML={{
                          __html: offering_cards_id?.paragraph,
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferingSection;
