'use client';

import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import { ProductSpecificationGroupM2M } from '@/app/_types/components/product';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './OfferingSection.module.scss';

type OfferingSectionProps = {
  offeringData: ProductSpecificationGroupM2M[];
  paragraph?: string | null;
  preText?: string | null;
  title?: string | null;
  filesMeta: FilesMeta;
};

const OfferingSection = (props: OfferingSectionProps) => {
  const { title, paragraph, preText, offeringData, filesMeta } = props;

  const [activeTab, setActiveTab] = useState(0);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const offeringListRef = useRef<HTMLDivElement | null>(null);
  const offeringContentContainerRef = useRef<HTMLDivElement | null>(null);
  const stickyWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (offeringListRef.current) {
      offeringListRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!offeringContentContainerRef.current || !stickyWrapperRef.current)
        return;

      const stickyWrapperRect =
        stickyWrapperRef.current.getBoundingClientRect();
      const contentRect =
        offeringContentContainerRef.current.getBoundingClientRect();

      if (
        stickyWrapperRect.top <= 0 &&
        stickyWrapperRect.bottom >= contentRect.height
      ) {
        offeringContentContainerRef.current.classList.add(Styles.fixedPosition);
      } else {
        offeringContentContainerRef.current.classList.remove(
          Styles.fixedPosition,
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={stickyWrapperRef} className={Styles.stickyWrapper}>
      <div className="container">
        <div className={Styles.offeringWrapper}>
          <div
            ref={offeringContentContainerRef}
            className={Styles.offeringContentContainer}
          >
            <div className={Styles.offeringContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {preText && <h3 className="subTitle">{preText}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div
                className={cn(
                  Styles.offeringContentDetails,
                  'editorDescription',
                )}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}

            <div
              ref={offeringContentContainerRef}
              className={Styles.offeringContentTabContainer}
            >
              <div className={Styles.tabNavigation}>
                {offeringData?.map(
                  ({ product_specification_group_id }, index) => (
                    <button
                      key={index}
                      ref={(el) => {
                        if (el) {
                          tabRefs.current[index] = el;
                        }
                      }}
                      className={`${Styles.tabButton} ${activeTab === index ? Styles.active : ''}`}
                      onClick={() => handleTabClick(index)}
                    >
                      <h3>
                        {typeof product_specification_group_id === 'object'
                          ? product_specification_group_id?.title
                          : ''}
                      </h3>
                    </button>
                  ),
                )}
              </div>
            </div>
          </div>

          {offeringData[activeTab] && (
            <div ref={offeringListRef} className={Styles.offeringList}>
              {typeof offeringData[activeTab].product_specification_group_id ===
                'object' &&
                offeringData[
                  activeTab
                ].product_specification_group_id?.product_specifications?.map(
                  (spec, index) => {
                    if (
                      typeof spec === 'number' ||
                      typeof spec.product_specifications_id === 'number'
                    ) {
                      return null;
                    }

                    return (
                      <div key={index} className={Styles.offeringCategory}>
                        {spec.product_specifications_id?.title && (
                          <div className={Styles.offeringCategoryHeading}>
                            <h4>{spec.product_specifications_id.title}</h4>
                          </div>
                        )}
                        {spec.product_specifications_id?.offering_cards?.map(
                          ({ offering_cards_id }, index) => (
                            <div key={index} className={Styles.offeringCard}>
                              <NoDownloadImage
                                src={
                                  getDirectusAssetURI(
                                    offering_cards_id?.icon ?? '',
                                  ) || ''
                                }
                                alt={getAltProperty(
                                  filesMeta,
                                  offering_cards_id?.icon ?? '',
                                  'Banner image',
                                )}
                                width={80}
                                height={80}
                              />
                              <div className={Styles.offeringCardBody}>
                                <div className="headingElement">
                                  <h3 className="titleSm">
                                    {offering_cards_id?.title}
                                  </h3>
                                </div>
                                {offering_cards_id?.paragraph && (
                                  <div
                                    className={cn(
                                      Styles.description,
                                      'editorDescription',
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: offering_cards_id?.paragraph,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    );
                  },
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferingSection;
