import cn from 'classnames';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import { useWindowSize } from '@/app/_hooks/window-size.hook';
import { CopyComponent } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './UmaPageContent.module.scss';

type PageContentTypes = CopyComponent & {
  smallContent?: boolean;
  filesMeta: FilesMeta;
};

const PageContent = (props: PageContentTypes) => {
  const {
    pre_text,
    title,
    paragraph,
    smallContent = false,
    cta_text,
    cta_url,
    cta_new_tab,
    cta2_text,
    cta2_url,
    cta2_new_tab,
    image,
    filesMeta,
  } = props;

  const { width } = useWindowSize();

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div
            className={cn(
              Styles.pageContentContainer,
              smallContent && Styles.smallContent,
            )}
          >
            <div className={Styles.pageContentHeadingSection}>
              {title && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                  {title && <h2 className="titleXl">{title}</h2>}
                </div>
              )}
            </div>
            {paragraph && (
              <div className={Styles.pageContentSection}>
                <div className={Styles.pageContentTextWrapper}>
                  <div
                    className={Styles.pageContentDetails}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                  {image && width && width > 992 && (
                    <div className={Styles.pageContentDetailsImage}>
                      <NoDownloadImage
                        src={getDirectusAssetURI(image ?? '') || ''}
                        alt={getAltProperty(
                          filesMeta,
                          image ?? '',
                          'product logo',
                        )}
                        width={740}
                        height={360}
                        quality={100}
                      />
                    </div>
                  )}
                </div>
                <div className={Styles.buttonWrapper}>
                  {cta_text && cta_url && (
                    <ButtonCustom
                      btnText={cta_text}
                      className="btnPrimary"
                      href={cta_url ?? ''}
                      extraClass={Styles.pageContentBtn}
                      newTab={cta_new_tab}
                    />
                  )}
                  {cta2_text && cta2_url && (
                    <ButtonCustom
                      btnText={cta2_text}
                      className="btnPrimaryOutline"
                      href={cta2_url ?? ''}
                      extraClass={Styles.pageContentBtn}
                      newTab={cta2_new_tab}
                    />
                  )}
                </div>
                {image && width && width <= 992 && (
                  <div className={Styles.mobileImageContainer}>
                    <NoDownloadImage
                      src={getDirectusAssetURI(image ?? '') || ''}
                      alt={getAltProperty(
                        filesMeta,
                        image ?? '',
                        'product logo',
                      )}
                      width={740}
                      height={360}
                      quality={100}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
