'use client';
import { Divider, Form, Radio } from 'antd';
import React from 'react';

import RadioCustom from '@/app/_components/radio/RadioCustom';

import Styles from './RiskAssessmentQuestionnaire.module.scss';

const RiskAssessmentQuestionnaire = () => {
  return (
    <div className="container">
      <div className={Styles.questionnaireWrapper}>
        <div className="mb-4">
          <div className="headingElement full-width mb-4">
            <h3 className="titleSm text-underline">
              B. Risk Assessment Questionnaire
            </h3>
          </div>
          <p>
            The six questions below highlight specific areas of concern
            regarding cyber-related risks for a business
          </p>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">
            Does your Business have an active, comprehensive, paid for internet
            security software installed on all computer systems?
          </p>
          <Form.Item
            name="security_software"
            key="securitySoftware"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="security_software"
              key="securitySoftware"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">Data Backup</p>
          <p className="text-neutral07">
            Do you back up your data on a daily / weekly basis?
          </p>
          <Form.Item
            name="data_backup_basis"
            key="dataBackupBasis"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="data_backup_basis"
              key="dataBackupBasis"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
          <p className="text-neutral07">
            Do you perform recovery testing at least once per year?
          </p>
          <Form.Item
            name="data_recovery_testing"
            key="dataRecoveryTesting"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="data_recovery_testing"
              key="dataRecoveryTesting"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">
            Is your data stored separately from your main computer e.g. via the
            cloud or on and offline hard disk?
          </p>
          <Form.Item
            name="data_separate_storage"
            key="dataSeparate"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="data_separate_storage"
              key="dataSeparate"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">
            Do you regularly update and patch your computers so that they always
            have the latest security patches installed?
          </p>
          <Form.Item
            name="security_patch"
            key="dataSecurityPatch"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="security_patch"
              key="dataSecurityPatch"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">
            Are all the emails received and sent by your workplace computers and
            network checked for viruses / malware via an email filter?
          </p>
          <Form.Item
            name="virus_check"
            key="virusCheck"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="virus_check"
              key="virusCheck"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={Styles.questionSection}>
          <p className="strong mb-1 base-font">
            Are your employees regularly advised about the secure use of their
            workplace computer, expecially regarding the dangers of using the
            internet / email?
          </p>
          <Form.Item
            name="secure_workspace"
            key="secureWorkspace"
            className="customInput"
            required={true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Radio.Group
              name="secure_workspace"
              key="secureWorkspace"
              className={Styles.radioGroup}
            >
              <RadioCustom name={'yes'} label={'Yes'} value={'yes'} />
              <RadioCustom name={'no'} label={'No'} value={'no'} />
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="caption">
          <p>
            If you answered no to any of the questions above, your company could
            be at risk and you should contact your IT provider, and/or insurance
            broker, with regards to the possible software/insurance solutions.
          </p>
        </div>
      </div>
      <Divider className={Styles.formDivider} />
    </div>
  );
};

export default RiskAssessmentQuestionnaire;
