import { convert } from 'html-to-text';
import Link from 'next/link';
import React from 'react';

import { Blog } from '@/app/_types/blog';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { formatDate } from '@/app/_utils/date-format';

import ButtonCustom from '../button/ButtonCustom';
import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './BlogArticleCards.module.scss';

type BlogArticleCardsTypes = {
  blogList: Blog[];
  title?: string | null;
  preText?: string | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
  blogBaseUrl?: string;
  filesMeta: FilesMeta;
};

const BlogArticleCards = (props: BlogArticleCardsTypes) => {
  const { preText, title, ctaText, ctaUrl, blogList, blogBaseUrl, filesMeta } =
    props;

  let href = blogBaseUrl ? `${blogBaseUrl.replace(/\/$/, '')}` : '';

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.blogArticleCardsWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {blogList && (
            <div className={Styles.blogsContainer}>
              <div className={Styles.blogsCardsList}>
                {blogList.map((blog, index) => {
                  return (
                    <Link
                      href={`${href}/${blog.slug}`}
                      key={index}
                      className={Styles.blogCard}
                    >
                      <div className={Styles.blogCardImageContainer}>
                        <NoDownloadImage
                          src={
                            getDirectusAssetURI(blog?.preview_image ?? '') ?? ''
                          }
                          alt={getAltProperty(
                            filesMeta,
                            blog?.preview_image ?? '',
                            'blog',
                          )}
                          className={Styles.blogCardImage}
                          width={860}
                          height={652}
                        />
                      </div>
                      <div className={Styles.blogCardContent}>
                        <div className={Styles.blogCardHeader}>
                          <h3 className={Styles.blogCardTitle}>{blog.title}</h3>
                          {blog.date ||
                            (blog.author && (
                              <div className={Styles.blogCardMeta}>
                                {blog.date && (
                                  <p className={Styles.blogCardDate}>
                                    {formatDate(blog.date)}
                                  </p>
                                )}
                                {blog.author && (
                                  <p className={Styles.blogCardAuthor}>
                                    {blog.author}
                                  </p>
                                )}
                              </div>
                            ))}
                        </div>
                        {blog?.content && (
                          <div className={Styles.blogCardDescription}>
                            {convert(blog?.content)}
                          </div>
                        )}
                      </div>
                    </Link>
                  );
                })}
              </div>
              {ctaText && ctaUrl && (
                <ButtonCustom href={ctaUrl} btnText={ctaText} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogArticleCards;
