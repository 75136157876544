import React from 'react';

import { CopyComponent } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './RiskApproachCard.module.scss';

type Props = CopyComponent & {
  filesMeta: FilesMeta;
};

const RiskApproachCard = (content: Props) => {
  const { pre_text, title, paragraph, image, filesMeta } = content;

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.pageContentWrapper}>
          <div className={Styles.pageContentContainer}>
            <div className={Styles.pageContentHeadingSection}>
              {pre_text && (
                <div className="headingElement">
                  {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                </div>
              )}
            </div>

            <div className={Styles.pageContentDetails}>
              {title && (
                <div className="headingElement">
                  <h2 className="titleXl">{title}</h2>
                </div>
              )}

              {paragraph && (
                <div
                  className={Styles.descriptionWrapper}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                ></div>
              )}

              {image && (
                <div className={Styles.imageWrapper}>
                  <NoDownloadImage
                    src={getDirectusAssetURI(image) || ''}
                    alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                    width={864}
                    height={347}
                    className={Styles.pageContentImage}
                    priority
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskApproachCard;
