'use client';

import React, { useMemo } from 'react';
import slugify from 'react-slugify';

import CustomTabs, {
  CustomTabsProps,
} from '@/app/_components/custom-tabs/CustomTabs';
import { ProductM2M } from '@/app/_types/components/product-category';
import { FilesMeta } from '@/app/_types/file';

import Styles from './ProductListing.module.scss';

type ProductListingProps = {
  heading?: string | null;
  subHeading?: string | null;
  products?: ProductM2M[];
  filesMeta?: FilesMeta;
};

const ProductListing = (props: ProductListingProps) => {
  const { heading, subHeading, products, filesMeta } = props;

  const productDetails = useMemo(
    () =>
      products
        ?.map((productM2M) => {
          const product = productM2M.products_id;

          return typeof product === 'object' && product !== null
            ? { ...product, filesMeta }
            : null;
        })
        .filter(Boolean) as CustomTabsProps['tabsList'],
    [products],
  );

  if (!products || products.length === 0) {
    return null;
  }

  return (
    <div id={slugify(heading)}>
      <div className="pageSection">
        <div className={Styles.productWrapper}>
          <div className="container">
            <div className={Styles.sectionHeadingContainer}>
              <div className={Styles.sectionLeftContent}>
                {heading && (
                  <div className="headingElement">
                    {subHeading && <h3 className="subTitle">{subHeading}</h3>}
                    {heading && <h2 className="titleXl">{heading}</h2>}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            {productDetails && (
              <CustomTabs tabsList={productDetails} filesMeta={filesMeta} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
