'use client';

import { Form, message } from 'antd';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import slugify from 'react-slugify';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '@/app/_components/form/FormInput';
import ThankYou from '@/app/_components/thankyou/ThankYou';
import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type Props = FormType & {
  noGrid?: boolean;
  handleFormSuccess?: () => void;
  filesMeta?: FilesMeta;
};

const ContactForm = ({ handleFormSuccess, noGrid, ...props }: Props) => {
  const pathname = usePathname();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const saveData = saveFormData.bind(
    null,
    pathname,
    props.submission_key,
    props.id,
  );
  const [success, setSuccess] = useState(false);
  const contactFormRef = useRef<HTMLDivElement | null>(null);
  const offset = 120;

  useEffect(() => {
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (
      activeSection === slugify(props.submission_key) &&
      contactFormRef.current
    ) {
      const scrollPosition = contactFormRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathname]);

  const initialValues = useMemo(() => {
    const values = props.form_inputs?.reduce((acc, curr) => {
      const itemName = 'name' in curr.item ? curr.item.name?.toString() : null;

      if (!itemName) {
        return { ...acc };
      }
      return { ...acc, [itemName]: '' };
    }, {});

    form.setFieldsValue(values);
    return values;
  }, [form]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  const handleSubmit = async (data: any) => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      setSuccess(false);
    } else {
      form.resetFields();
      if (contactFormRef.current) {
        const scrollPosition = contactFormRef.current.offsetTop - offset;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
      setSuccess(true);
      setTurnstileToken(null);
      if (handleFormSuccess) {
        handleFormSuccess();
      }
    }
  };
  const thankYouData = {
    submittedTitle: props.submitted_title,
    submittedParagraph: props.submitted_paragraph,
    submittedImage: props.submitted_image,
  };

  return (
    <div
      className={cn(noGrid ? '' : 'pageSection', Styles.pageSection)}
      id={props.submission_key}
      ref={contactFormRef}
    >
      <div className={Styles.contactFormWrapper}>
        <div className="container">
          {contextHolder}

          <div className={noGrid ? '' : Styles.contactFormContainer}>
            <div className={Styles.formSection}>
              <div className={Styles.formHeaderWrapper}>
                {props.title && (
                  <div className="headingElement">
                    {props.pre_text && (
                      <h3 className="subTitle">{props.pre_text}</h3>
                    )}
                    {props.title && (
                      <h2
                        className="titleXl"
                        dangerouslySetInnerHTML={{ __html: props.title }}
                      ></h2>
                    )}
                  </div>
                )}

                {props.description && (
                  <div
                    className={Styles.formHeaderDescription}
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  ></div>
                )}
              </div>
              {!success && (
                <Form
                  className={Styles.contactForm}
                  size="large"
                  form={form}
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  <div
                    className={`${Styles.contactFormField} ${noGrid ? Styles.noGrid : ''}`}
                  >
                    {props.form_inputs.map((input) => (
                      <FormInput key={input.id} input={input} form={form} />
                    ))}
                  </div>
                  <CloudflareTurnstile
                    onSuccess={getTurnstileToken}
                    onError={() => setIsTurnstileLoading(false)}
                    onLoad={() => setIsTurnstileLoading(true)}
                  />
                  <div>
                    <ButtonCustom
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched() ||
                        !isFormValid ||
                        isTurnstileLoading
                      }
                    >
                      {props.submit_text}
                    </ButtonCustom>
                  </div>
                </Form>
              )}
            </div>

            {success && (
              <ThankYou {...thankYouData} filesMeta={props.filesMeta} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
