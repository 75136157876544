'use client';

import React, { useMemo } from 'react';

import CampaignSlider from '@/app/_components/campaign-slider/CampaignSlider';
import HelpCard from '@/app/_components/help-card/HelpCard';
import OurProducts from '@/app/_components/our-products/OurProduct';
import Partners from '@/app/_components/partners/Partners';
import VideoPlayer from '@/app/_components/video/VideoPlayer';
import { FilesMeta } from '@/app/_types/file';
import { HomeVariation1 } from '@/app/_types/home';

import CtaBanner from './CtaBanner';

type HomeVariation1Props = HomeVariation1 & {
  filesMeta: FilesMeta;
};

const HomePageVariation1 = (props: HomeVariation1Props) => {
  const {
    header_component,
    products,
    video,
    partners,
    call_to_action,
    campaigns,
    filesMeta,
  } = props;

  const updatedProducts = useMemo(() => {
    return (
      products &&
      products?.cards?.map(({ card_id }: any) => {
        const { category, ...rest } = card_id;

        return {
          ...rest,
          url: category?.navigation?.permalink || '',
        };
      })
    );
  }, [products]);

  return (
    <>
      {header_component && (
        <CtaBanner {...header_component} filesMeta={filesMeta} />
      )}
      {updatedProducts && updatedProducts.length > 0 && (
        <OurProducts
          products={updatedProducts}
          title={products.title}
          preText={products.pre_text}
          description={products.paragraph}
          filesMeta={filesMeta}
        />
      )}
      {video && <VideoPlayer video={video} />}
      {partners && <Partners {...partners} filesMeta={filesMeta} />}
      {call_to_action && <HelpCard {...call_to_action} filesMeta={filesMeta} />}
      {campaigns && campaigns.length > 0 && (
        <CampaignSlider
          slides={campaigns}
          fullWidth={true}
          filesMeta={filesMeta}
        />
      )}
    </>
  );
};

export default HomePageVariation1;
