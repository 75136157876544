'use client';

import cn from 'classnames';
import React, { useRef, useState } from 'react';

import { LeadershipTabM2M } from '@/app/_types/about-us';

import MobileDropdown from './MobileDropdown';
import Styles from './VerticalTab.module.scss';

type Props = {
  leadership_pre_text: string;
  leadership_title: string;
  leadership: LeadershipTabM2M[];
};

const VerticalTab = (props: Props) => {
  const { leadership_pre_text, leadership_title, leadership } = props;
  const [activeTab, setActiveTab] = useState(
    leadership[0].leadership_tab_id.id,
  );
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const handleTabClick = (tabId: string, index: number) => {
    setActiveTab(tabId);

    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <div className="pageSection">
      <div className="container">
        {leadership_pre_text && (
          <div className="headingElement">
            <h3 className="subTitle"> {leadership_pre_text} </h3>
            {leadership_title && (
              <h2 className="titleXl">{leadership_title}</h2>
            )}
          </div>
        )}

        <div className={Styles.desktopNavigation}>
          <div className={Styles.verticalTabWrapper}>
            <div className={Styles.tabNavigation}>
              {leadership.map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => {
                    if (el) {
                      tabRefs.current[index] = el;
                    }
                  }}
                  className={`${Styles.tabButton} ${activeTab === tab.leadership_tab_id.id ? Styles.active : ''}`}
                  onClick={() =>
                    handleTabClick(tab.leadership_tab_id.id, index)
                  }
                >
                  <h3>{tab.leadership_tab_id.group_name}</h3>
                </button>
              ))}
            </div>

            <div className={Styles.tabContainer}>
              {leadership.map((company, index) => (
                <div
                  key={index}
                  id={company.leadership_tab_id.group_name}
                  className={`${Styles.tabContent} ${
                    activeTab === company.leadership_tab_id.id
                      ? Styles.activeContent
                      : ''
                  }`}
                >
                  {activeTab === company.leadership_tab_id.id &&
                    company.leadership_tab_id.members.map(
                      (employee, empIndex) => (
                        <div
                          key={empIndex}
                          className={cn(
                            'headingElement',
                            Styles.companyDetails,
                          )}
                        >
                          <h4 className="titleSm medium employeeName">
                            {employee.members_id.name}
                          </h4>
                          <p className="text-primary medium employeeTitle">
                            {employee.members_id.title.toUpperCase()}
                          </p>
                        </div>
                      ),
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={Styles.mobileNavigation}>
          <MobileDropdown {...props} />
        </div>
      </div>
    </div>
  );
};

export default VerticalTab;
