import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { formatDate } from '@/app/_utils/date-format';

import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './MediaCard.module.scss';

type Props = {
  title?: string | null;
  content?: string | null;
  category?: string | null;
  tags?: (string | null | undefined)[] | null;
  date?: string | null;
  author?: string | null;
  image?: string | null;
  document?: string | null;
  filesMeta?: FilesMeta;
  landscapeMode?: boolean;
  shadowBox?: boolean;
  baseUrl?: string | null;
  slug?: string | null;
};

const MediaCard = (props: Props) => {
  const {
    title,
    content,
    category,
    tags,
    date,
    author,
    image,
    document,
    baseUrl,
    slug,
    filesMeta = {},
    landscapeMode = false,
    shadowBox = false,
  } = props;

  let href = baseUrl ? `${baseUrl.replace(/\/$/, '')}/${slug}` : '';
  let target = '_self';

  if (document) {
    href = getDirectusAssetURI(document) || '';
    target = '_blank';
  }

  return (
    <Link
      className={cn(
        Styles.mediaCard,
        landscapeMode && Styles.landscapeCard,
        shadowBox && Styles.shadowBoxCard,
      )}
      href={href}
      target={target}
    >
      {image && (
        <div className={Styles.mediaCardImageContainer}>
          <NoDownloadImage
            src={getDirectusAssetURI(image ?? '') || ''}
            alt={getAltProperty(filesMeta, title ?? '', 'card image')}
            className={Styles.mediaCardImage}
            width={532}
            height={484}
          />
        </div>
      )}

      <div className={Styles.mediaCardBody}>
        <div className={Styles.mediaCardContent}>
          {category && (
            <div className="headingElement">
              <h4 className="subTitle">{category}</h4>
            </div>
          )}
          {title && (
            <div className="headingElement">
              <h3 className="titleSm strong">{title}</h3>
            </div>
          )}
          {content && (
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}

          <ul className={Styles.metaData}>
            {date && <li>{formatDate(date)}</li>}
            {author && <li>{author}</li>}
          </ul>

          {tags && tags.length > 0 && (
            <ul className={Styles.tagsList}>
              {tags.map((tag, index) => (
                <li key={index}>
                  <div className={Styles.tag}>{tag}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Link>
  );
};

export default MediaCard;
