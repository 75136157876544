'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import MediaCard from '@/app/_components/media-card/MediaCard';
import ChevronLeft from '@/app/_icons/chevron-left.svg';
import { Blog } from '@/app/_types/blog';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { FilesMeta } from '@/app/_types/file';
import { MediaPage } from '@/app/_types/media-page';

import Styles from './Blogs.module.scss';

type Props = {
  media: Blog[],
  blogTitle: MediaPage['blog_title'];
  blogPreText: MediaPage['blog_pre_text'];
  blogBaseUrl?: string;
  filesMeta?: FilesMeta;
  breadcrumbs?: Breadcrumb[] | null;
  archivesPageNavigation?: string | null;
};

const Blogs = (props: Props) => {
  const {
    media,
    blogTitle,
    blogPreText,
    blogBaseUrl,
    filesMeta,
    archivesPageNavigation,
  } = props;

  const sortedMediaData = media
    ?.sort((a, b) => {
      const dateA = new Date(a.date || 0).getTime();
      const dateB = new Date(b.date || 0).getTime();

      return dateB - dateA;
    })
    .slice(0, 9);

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.educationalArticlesWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {blogPreText && <h3 className="subTitle">{blogPreText}</h3>}
                {blogTitle && <h2 className="titleXl">{blogTitle}</h2>}
              </div>
            </div>

            <div className={Styles.sectionRightContent}>
              <Link
                href={`${archivesPageNavigation}`}
                className={Styles.viewArchivesLink}
              >
                View archives
                <Image src={ChevronLeft} height={14} width={14} alt="arrow" />
              </Link>
            </div>
          </div>
            <div className={Styles.mediaList}>
              {sortedMediaData &&
                sortedMediaData.map((item, index) => (
                  <MediaCard
                    key={index}
                    title={item?.title}
                    tags={item?.tags}
                    date={item.date}
                    author={item.author}
                    image={item.preview_image}
                    landscapeMode={true}
                    shadowBox={true}
                    baseUrl={blogBaseUrl}
                    slug={item.slug}
                    filesMeta={filesMeta}
                  />
                ))}
            </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
