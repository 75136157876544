'use client';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';
import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './HelpCard.module.scss';

type HelpCardProps = CTABanner & {
  filesMeta?: FilesMeta;
};

const HelpCard = (props: HelpCardProps) => {
  const {
    pre_text,
    title,
    title_2,
    paragraph,
    call_to_action,
    image,
    filesMeta = {},
    contact_number,
    operating_hours,
  } = props;

  const pathName = usePathname();
  const helpCardRef = useRef<HTMLDivElement | null>(null);
  const offset = 120;

  useEffect(() => {
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (
      activeSection === (slugify(pre_text) || slugify(title)) &&
      helpCardRef.current
    ) {
      const scrollPosition = helpCardRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className="pageSection"
      ref={helpCardRef}
      id={slugify(pre_text) || slugify(title)}
    >
      <div className={Styles.helpCardContainer}>
        <div className="container">
          <div className={Styles.helpCardContent}>
            <div className={Styles.leftContent}>
              <div className="headingElement">
                {pre_text && <h4 className="subTitle">{pre_text}</h4>}
                {title && (
                  <h3 className="titleXl">
                    {title}{' '}
                    {title_2 && <span className="strong">{title_2}</span>}
                  </h3>
                )}
              </div>

              {paragraph && (
                <div
                  className={cn(Styles.description, 'editorDescription')}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              )}

              {contact_number && (
                <div className={Styles.contactContainer}>
                  <div className="headingElement">
                    {title && <h4 className="titleMd">{contact_number}</h4>}
                  </div>

                  {operating_hours && (
                    <div className={Styles.contactDescription}>
                      {operating_hours}
                    </div>
                  )}
                </div>
              )}

              {call_to_action?.text && (
                <ButtonCustom
                  btnText={call_to_action?.text}
                  className="btnPrimaryAlt"
                  extraClass={Styles.ctaCardBtn}
                  href={
                    call_to_action?.pdf
                      ? getDirectusAssetURI(call_to_action?.pdf ?? '') || ''
                      : (call_to_action?.url ?? '')
                  }
                  newTab={call_to_action?.new_tab || !!call_to_action?.pdf}
                />
              )}
            </div>
            <div className={Styles.rightContent}>
              {image && (
                <NoDownloadImage
                  src={getDirectusAssetURI(image) ?? ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                  width={860}
                  height={560}
                  className={Styles.cardImage}
                  quality={90}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
