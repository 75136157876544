'use client';

import Image from 'next/image';
import React, { useRef, useState } from 'react';

import playButton from '@/app/_images/play-btn.png';
import { Video } from '@/app/_types/components/video';

import Styles from './VideoPlayer.module.scss';

type Props = {
  video: Video;
};

const VideoPlayer = ({ video }: Props) => {
  const { url } = video;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(true);

  const getEmbedUrl = (youtubeUrl: string) => {
    const videoIdMatch = youtubeUrl.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|user\/\S*\/\S*\/|channel\/\S*\/|c\/\S*\/|@?\S*\/|@?\S*\/))([^#&?]*).*/,
    );
    const videoId = videoIdMatch && videoIdMatch[1] ? videoIdMatch[1] : '';

    return `https://www.youtube.com/embed/${videoId}`;
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  return (
    <div className="pageSection">
      <div className={Styles.videoWrapper}>
        <div className={Styles.videoPlayerController}>
          {!playing && (
            <button className={Styles.videoBtn} onClick={togglePlayPause}>
              <Image alt="Play" height={60} width={60} src={playButton} />
            </button>
          )}
        </div>
        <div className={Styles.videoPlayer} onClick={togglePlayPause}>
          {playing && (
            <div className={Styles.videoPlayer}>
              <iframe
                className={Styles.videoPlayerFrame}
                width="100%"
                src={getEmbedUrl(url)}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
