import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Styles from './History.module.scss';

type HistoryTypes = {
  content: Array<{
    history_tab_id: {
      id: string;
      paragraph: string;
      year: string;
    };
  }>;
};

const History = (props: HistoryTypes) => {
  const { content } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const listRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (!listRef.current) return;

    const container = listRef.current;
    const items = container.children;
    let firstVisibleIndex = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i] as HTMLElement;
      const rect = item.getBoundingClientRect();
      const parentRect = container.getBoundingClientRect();

      if (rect.top >= parentRect.top) {
        firstVisibleIndex = i;
        break;
      }
    }

    setActiveIndex(firstVisibleIndex);
  };
  

  useEffect(() => {
    const refCurrent = listRef.current;

    refCurrent?.addEventListener('scroll', handleScroll);
    return () => {
      refCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={Styles.historyWrapper}>
      <div className={Styles.historyListLine} />
      <div className={Styles.historyList} ref={listRef}>
        {content.map((data, index) => (
          <div
            key={index}
            className={cn(
              Styles.historyItem,
              activeIndex === index && Styles.active,
            )}
          >
            <div className={Styles.historyTitle}>
              {data.history_tab_id.year && (
                <div className="headingElement text-primary">
                  <h4 className="titleMd">
                    {data.history_tab_id.year.toLocaleString()}
                  </h4>
                </div>
              )}
            </div>
            {data.history_tab_id.paragraph && (
              <div className={Styles.historyDescription}>
                <div className={Styles.yellowDot}></div>
                <div
                  className="normal"
                  dangerouslySetInnerHTML={{
                    __html: data.history_tab_id.paragraph,
                  }}
                ></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
