'use client';

import { Form, message } from 'antd';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '@/app/_components/form/FormInput';
import NoDownloadImage from '@/app/_components/no-download-image/NoDownloadImage';
import ThankYou from '@/app/_components/thankyou/ThankYou';
import { productLandingPageFormScroll } from '@/app/_constants/product.constants';
import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type ContactFormProps = FormType & {
  filesMeta: FilesMeta;
};

const ContactForm = (props: ContactFormProps) => {
  const {
    title,
    pre_text,
    submit_text,
    submission_key,
    form_inputs,
    description,
    image,
    filesMeta,
    submitted_title,
    submitted_paragraph,
    submitted_image,
  } = props;

  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const values = Form.useWatch([], form);
  const [messageApi, contextHolder] = message.useMessage();
  const pathname = usePathname();
  const saveData = saveFormData.bind(null, pathname, submission_key, props.id);
  const [success, setSuccess] = useState(false);
  const contactFormRef = useRef<HTMLDivElement | null>(null);
  const offset = 120;

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const pathName = usePathname();

  useEffect(() => {
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (
      activeSection === productLandingPageFormScroll?.form &&
      contactFormRef.current
    ) {
      const scrollPosition = contactFormRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  const handleSubmit = async (data: any): Promise<boolean> => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return false;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      setSuccess(false);
      return false;
    } else {
      form.resetFields();
      if (contactFormRef.current) {
        const scrollPosition = contactFormRef.current.offsetTop - offset;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
      setSuccess(true);
      setTurnstileToken(null);
      return true;
    }
  };

  const thankYouData = {
    submittedTitle: submitted_title,
    submittedParagraph: submitted_paragraph,
    submittedImage: submitted_image,
  };

  return (
    <div
      className={cn('pageSection', Styles.contactFormSection)}
      id={productLandingPageFormScroll?.form}
      ref={contactFormRef}
    >
      <div className={Styles.contactFormWrapper}>
        <div className="container">
          {contextHolder}
          <div className={Styles.contactFormContainer}>
            <div className={Styles.formSection}>
              <div className={Styles.formHeaderWrapper}>
                {title && (
                  <div className="headingElement">
                    {pre_text && <h3 className="subTitle">{pre_text}</h3>}
                    {title && <h2 className="titleXl">{title}</h2>}
                  </div>
                )}

                {description && (
                  <div
                    className={Styles.formHeaderDescription}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                )}
              </div>
              {!success && (
                <Form
                  className={Styles.contactForm}
                  size="large"
                  form={form}
                  onFinish={handleSubmit}
                >
                  <div className={Styles.contactFormField}>
                    {form_inputs.map((input) => (
                      <FormInput key={input.id} input={input} form={form} />
                    ))}
                  </div>
                  <CloudflareTurnstile
                    onSuccess={getTurnstileToken}
                    onError={() => setIsTurnstileLoading(false)}
                    onLoad={() => setIsTurnstileLoading(true)}
                  />
                  <div>
                    <ButtonCustom
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched() ||
                        !isFormValid ||
                        isTurnstileLoading
                      }
                    >
                      {submit_text}
                    </ButtonCustom>
                  </div>
                </Form>
              )}
            </div>
            {!success && (
              <div className={Styles.contactFormImageContainer}>
                <NoDownloadImage
                  src={getDirectusAssetURI(image ?? '') || ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Contact image')}
                  fill
                />
              </div>
            )}
            {success && <ThankYou {...thankYouData} filesMeta={filesMeta} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
